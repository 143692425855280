/* Investments.css */
.responsive-text {
    font-size: 1rem;
  }
  
  @media (max-width: 1200px) {
    .responsive-text {
      font-size: 0.9rem;
    }
  }
  
  /* @media (max-width: 992px) {
    .responsive-text {
      font-size: 0.8rem;
    }
  } */
  
  /* @media (max-width: 768px) {
    .responsive-text {
      font-size: 0.7rem;
    }
  }
  
  @media (max-width: 576px) {
    .responsive-text {
      font-size: 0.6rem;
    }
  } */